import React from 'react'
import './Hero.scss'
import { Btn, ContentBox, Griditem, Gridwrapper, InterGradient, RedHatDisplay } from '../Styled'

const Hero = () => {
    let vectorID = require('../../assets/IngenieriaDigital_Vector.png')
    let iconFigure = require('../../assets/icon.png')
    let text = require('../../assets/Nosotros estamos aquí para ayudarte.png')
    let textMovil = require('../../assets/nostros_estamos_para_ayudarte_movil.png')
  return (
    <div className='sectionHero'>
        <Gridwrapper
            varwidth = '90%'
            varmaxwidth = '1256px'
            gridtemplatecolumns = '69.5% 30%'
            varminheight = '83vh'
            justifyContent = 'start'
            grid1350 = '90% 9%'
            grid1024 = '100%'
            className='columnsHero'
        >
            <Griditem
                vardisplayTablet = 'flex'
            >
                <InterGradient
                    varfontSize = '6.8vh'
                    varweight = '800' 
                    varfontFamily = '"Red Hat Display", sans-serif'
                    varbackground = 'linear-gradient(250deg, #5A2682 0%, #462A92 40%, #2A2FA9 100%)'
                    vartextAlign = 'left'
                    varlineHeight = '1.2em'
                    varpadding = '0 0 20px 0'
                    varmaxWidth = '654px'
                    varwidth = '80%'
                    textAlignTablet = 'center'
                    fontSizeMobile600 = '36px'
                    smFontSize ='34px'
                    MovilPadd = '0 0 20px 0'
                >
                    Vende por Internet como jamás lo pensaste

                </InterGradient>
                <RedHatDisplay
                    varcolor='#2A2FAB'
                    varfontSize = '24px'
                    varweight = '400'
                    vartextAlign = 'left'
                    textAlignTablet = 'center'
                    smFontSize = '18px'
                    varpadding = '0 0 4px'
                >
                    Reestructura tu <strong>eCommerce</strong> con
                </RedHatDisplay>
                <img src={vectorID} alt="Ingenieria digital vector" className='vector'/>
                <Btn
                    backgcolor = '#F2F3FF'
                    varcolor = '#2A2FAB'
                    varfontsize = '18px'
                    varweight = '700'
                    varpadding = '16px 28px'
                    varRadius = '0px'
                    varmaxwidth = '341px'
                    varwidth = '80%' 
                    smFontSize = '12px'
                    smWidth = '46%'
                    smPadd = '12px 28px'
                    smMargin = '6px 0 0'
                    className='btn'
                    onClick={() => window.location.href = '/calendario'} 
                >
                    Empecemos ahora
                </Btn>
            </Griditem>
            <Griditem
                className='columnbackimage'
                tabletHeight = '600px'    
                movilHeight = '440px'
            />
        </Gridwrapper>
        <Gridwrapper
        gridtemplatecolumns ='6.7% 82% 6.7%'
        varmaxwidth = '1084px'
        varwidth = '96%'
        varGridGap = '24px'
        varmargin = '58px auto'
        columnsMovil = '8.7% 70% 8.7%'
        >
          <Griditem>
            <img src={iconFigure} alt="icon" style={{width:'100%'}} />
          </Griditem>
          <Griditem>
            <RedHatDisplay
              varfontSize = '20px'
              varlineHeight = '1.5em'
              varcolor = '#263238'
              varweight = '400'
              smFontSize = '15px'
            >
              Crear una tienda puede ser abrumador por <strong>desconocer lo que no sabes</strong> o si lo haces bien. <br />
              Sin un rumbo claro, tu tienda en línea puede volverse un caos.
            </RedHatDisplay>
          </Griditem>
          <Griditem>
           <img src={iconFigure} alt="icon" style={{width:'100%'}} />
          </Griditem>
        </Gridwrapper>
        <ContentBox
            varmaxwidth = '1440px'
            varwidth = '100%'
            margin = '0px 0px 81px'
            className='contain-nosotros'
        >
            <img src={text} alt="Nosotros estamos para ayudarte" className='nosotros-img' />
            <img src={textMovil} alt="Nosotros estamos para ayudarte" className='nosotros-movil-img' />
            
        </ContentBox>
    </div>
  )
}

export default Hero