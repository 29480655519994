import React from 'react'
import { Btn, ContentBox, Griditem, Gridwrapper, RedHatDisplay } from '../Styled'
import './Superleads.scss'
import { ArrowForwardOutlined } from '@mui/icons-material'

const Superleads = () => {
    let logo_superleads = require('../../assets/IngenieriaDigital_SuperLeads.png')
  return (
    <div className='sectionSuperleads'>
        <Gridwrapper
            varmaxwidth = '1279px'
            varwidth = '94%'
            gridtemplatecolumns = '40% 55.8%'
            justifyContent = 'space-between'
            columnsMovil = '100%'
            className='contain-grid-superleads'
        >
            <Griditem>
                <img src={logo_superleads} alt="logo_superleads" className='logo_superleads' />
            </Griditem>
            <Griditem>
                <ContentBox
                    varpadding = '0 90px 0 0'
                    vardisplay = 'flex'
                    varalignItems = 'end'
                    Movilpadding = '3px 30px 30px 10px'
                    varminheight = '223px'
                >
                    <RedHatDisplay
                        varfontSize = '48px'
                        vartextAlign = 'right'
                        varweight = '700'
                        varpadding = '0 0 32px'
                        varcolor = '#fff'
                        varlineHeight ='1.3em'
                        smFontSize  = '24px'
                        maxWidthMovil = '74%'
                        movilweight = '500'
                    >
                        La solución definitiva para mejorar toda tu comunicación
                    </RedHatDisplay>
                    <Btn
                        varRadius = '0px'
                        varmaxwidth = '290px'
                        varwidth = '80%'
                        backgcolor = '#fff'
                        varcolor = '#2A2FAB'
                        smFontSize = '13px'
                        smPadd   = '9px 15px'
                        smmaxwidth = '56%'
                        as="a" // Esto convierte el botón en un enlace
                        href="https://superleads.mx"
                        className='btn'
                    >
                        Empieza demo
                        <ArrowForwardOutlined />
                    </Btn>
                </ContentBox>
                
            </Griditem>
        </Gridwrapper>
        
    </div>
  )
}

export default Superleads