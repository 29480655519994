import React from 'react';
import { Griditem, Gridwrapper, InterGradient, RedHatDisplay } from '../Styled';
import './DecoracionTienda.scss';

const DecoracionTienda = ({ 
  imageMejoraTuTienda, 
  imageConectaVelas, 
  titleText, 
  descriptionText 
}) => {
  return (
    <div className='DecoracionTiendaSection'>
        <Gridwrapper
            gridtemplatecolumns='46.9% 53%'
            varwidth='100%'
            varmaxwidth='1240px'
            varalignItems='flex-start'
            columnsMovil = '86%'
        >
            <Griditem padd='11vh 0 0'>
                <img src={imageMejoraTuTienda} alt="Mejora tu tienda" className='imageMtt' />
                <InterGradient
                    varfontSize='30px'
                    varweight='400'
                    vartextAlign='left'
                    varpadding = '4px 0 12px'
                    smFontSize = '22px'
                >
                    {titleText}
                </InterGradient>
                <RedHatDisplay
                    varfontSize='14px'
                    varcolor='#263238'
                    varlineHeight='1.5em'
                    varmaxWidth='296px'
                    vartextAlign='left'
                >
                    {descriptionText}
                </RedHatDisplay>
            </Griditem>
            <Griditem>
                <img src={imageConectaVelas} alt="Conecta velas" className='img-tienda'/>
            </Griditem>
        </Gridwrapper>
    </div>
  );
}

export default DecoracionTienda;