import React from 'react';
import Hero from '../components/Hero/Hero';
import IDentidad from '../components/IDentidad/IDentidad';
import TextBanner from '../components/TextBanner/TextBanner';
import TiendaConectaVelas from '../components/DecoracionTienda/TiendaConectaVelas';
import TiendaZennatura from '../components/DecoracionTienda/TiendaZennatura';
import TiendaKittlekidge from '../components/DecoracionTienda/TiendaKittlekidge';
import TiendaJesala from '../components/DecoracionTienda/TiendaJesala';
import Superleads from '../components/Superleads/Superleads';
import Control from '../components/Control/Control';
import Contacto from '../components/Contacto/Contacto';
import ScrollSection from '../components/DecoracionTienda/ScrollingSection';

const Home = () => {
  return (
    <div style={{ backgroundColor: '#fafafa' }}>
      <Hero />
      <IDentidad />
      {/* Componente que maneja el scroll vertical para cambiar entre los 4 componentes */}
      <ScrollSection 
        components={[
          <TiendaConectaVelas />,
          <TiendaZennatura />,
          <TiendaKittlekidge />,
          <TiendaJesala />
        ]}
      />
      {/* <ScrollSection components={[<TiendaConectaVelas />, <TiendaZennatura />, <TiendaKittlekidge />, <TiendaJesala />]} /> */}
      <TextBanner />
      <Superleads />
      <Control />
      <Contacto />
    </div>
  );
};

export default Home;