import React, { useEffect } from 'react'
import './IDentidad.scss'
import { Btn, ContentBox, Griditem, Gridwrapper, RedHatDisplay } from '../Styled'
import { Code, Extension, LocalLibrary, Lock, Person } from '@mui/icons-material'
import { Box, Grid } from '@mui/material'

const IDentidad = () => {
    let iconFigure = require('../../assets/icon.png')

    useEffect(() => {
        const columns = document.querySelectorAll('.column-identidad');
        const secondColumn = columns[1];
        const section = document.querySelector('.sectionIDentidad');

        // Add the hovered class to the second column by default
        secondColumn.classList.add('hovered');
        
        columns.forEach((column) => {
          column.addEventListener('mouseenter', () => {
            // Remove hovered class from all columns
            columns.forEach(col => col.classList.remove('hovered'));
            // Add hovered class to the hovered column
            column.classList.add('hovered');
          });
        });

        section.addEventListener('mouseleave', () => {
          // Remove hovered class from all columns
          columns.forEach(col => col.classList.remove('hovered'));
          // Reapply the hovered class to the second column
          secondColumn.classList.add('hovered');
        });

      }, []);

  return (
    <div className='sectionIDentidad'>
        <Gridwrapper
            gridtemplatecolumns = '90% 9%'
            varalignItems = 'flex-start'
            varmargin = '0  0  0px'
            columnsMovil = '83% 9%'
        >
            <Griditem>
                <RedHatDisplay
                    varfontSize = '30px'
                    varweight = '800'
                    varcolor = '#2A2FAB'
                    smFontSize = '24px'
                >
                    ¿Qué es lo que necesitas?
                </RedHatDisplay>
            </Griditem>
            <Griditem>
                <img src={iconFigure} alt="iconFigure"  style={{width:'100%'}} />
            </Griditem>
        </Gridwrapper>
        <RedHatDisplay
            varfontSize = '16px'
            varcolor = '#263238'
            varweight = '400'
            varpadding = '0 0 77px'
            smFontSize = '14px'
            smPadding = '0 0 70px'
        >
            Tu <strong>IDentidad</strong>, nuestra <strong>ingeniería</strong>
        </RedHatDisplay>

        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
            <Grid item xs={12} md={4} style={{ borderTop: 'solid 0.5px #F1ABD4', borderBottom: 'solid 0.5px #F1ABD4'}} className='column-identidad'>
            <ContentBox
                        varmaxwidth = '300px'
                        varwidth = '90%'
                    >
                        <Gridwrapper
                            gridtemplatecolumns = '90% 9%'
                            varjustifyContent = 'flex-start'
                            varmaxwidth = '208px'
                            varwidth = '100%'
                            varmargin = '0 0 92px'
                            marginMovil = '0 0 50px'
                        >
                            <RedHatDisplay
                            varfontSize  = '16px'
                            varweight = '800'
                            varcolor = '#2A2FAB'
                            vartextAlign = 'left'
                            varlineHeight = '1.5em'
                            className='icon-color'                            
                            >
                                Enfoque personalizado
                            </RedHatDisplay>
                            
                            <Person className='icon-color'/>
                        </Gridwrapper>
                        <RedHatDisplay
                            varfontSize  = '16px'
                            varweight = '400'
                            varcolor = '#263238'
                            vartextAlign ='left'
                            varlineHeight = '1.2em'
                            className='icon-color'                           
                        >
                            Priorizamos tus necesidades desde el primer momento con <strong>entrevistas detalladas</strong> para comprender a fondo tu negocio, tus áreas de oportunidad y tus objetivos.
                        </RedHatDisplay>
                        <RedHatDisplay
                            varfontSize  = '16px'
                            varweight = '400'
                            varcolor = '#77216B'
                            vartextAlign ='left'
                            varlineHeight = '1.5em'
                            varpadding = '14px 0 0'
                            className='icon-color'
                        >
                           <strong>Cada proyecto es único</strong>, y así lo tratamos.
                        </RedHatDisplay>

                    </ContentBox>
                </Grid>
                <Grid item xs={12} md={4} className='column-identidad hovered' style={{ borderLeft: 'solid 0.5px #F1ABD4', borderBottom: 'solid 0.5px #F1ABD4', borderTop: 'solid 0.5px #F1ABD4',}} >
                    <ContentBox
                        varmaxwidth = '300px'
                        varwidth = '90%'
                    >
                        <Gridwrapper
                            gridtemplatecolumns = '90% 9%'
                            varjustifyContent = 'flex-start'
                            varmaxwidth = '238px'
                            varwidth = '100%'
                            varmargin = '0 0 92px'
                            marginMovil = '0 0 50px'
                        >
                            <RedHatDisplay
                            varfontSize  = '16px'
                            varweight = '700'
                            varlineHeight = '1.5em'
                            varcolor = '#2A2FAB'
                            vartextAlign = 'left'
                            className='icon-color' 
                            >
                                Integración y Optimización Tecnológica
                            </RedHatDisplay>
                            
                            <Code style={{color: '#fafafa'}}/>
                        </Gridwrapper>
                        <RedHatDisplay
                            varfontSize  = '16px'
                            varweight = '300'
                            varcolor = '#263238'
                            vartextAlign ='left'
                            varlineHeight = '1.2em'
                            className='icon-color' 
                        >
                            <strong>Google, Meta y TikTok</strong> a tu servicio, integrados a la perfección para que obtengas:                        
                        </RedHatDisplay>
                        <ul>
                            <li className='li-color'>Análisis precisos</li>
                            <li className='li-color'>Campañas optimizadas</li>
                            <li className='li-color'>Experiencia fluida</li>
                            <li className='li-color'>Rendimiento desde el inicio</li>
                        </ul>
                        
                    </ContentBox>
                </Grid>
                <Grid item xs={12} md={4} style={{ borderLeft: 'solid 0.5px #F1ABD4', borderBottom: 'solid 0.5px #F1ABD4', borderTop: 'solid 0.5px #F1ABD4',}} className='column-identidad'>
                <ContentBox
                        varmaxwidth = '300px'
                        varwidth = '90%'
                    >
                        <Gridwrapper
                            gridtemplatecolumns = '90% 9%'
                            varGridGap = '5px'
                            varjustifyContent = 'flex-start'
                            varmaxwidth = '238px'
                            varwidth = '100%'
                            varmargin = '0 0 92px'
                            marginMovil = '0 0 50px'
                        >
                            <RedHatDisplay
                            varfontSize  = '16px'
                            varweight = '800'
                            varcolor = '#2A2FAB'
                            vartextAlign = 'left'
                            varlineHeight = '1.5em'
                            className='icon-color'
                            >
                                Control total y protección de datos
                            </RedHatDisplay>
                         
                            <Lock className='icon-color'/>
                        </Gridwrapper>
                        <RedHatDisplay
                            varfontSize  = '16px'
                            varweight = '400'
                            varcolor = '#263238'
                            vartextAlign ='left'
                            varlineHeight = '1.2em'
                            className='icon-color'
                        >
                            Todas las cuentas importantes <strong>configuradas a tu nombre</strong> como dueño del negocio, con medidas de seguridad para <strong>proteger tu información</strong> más sensible.
                        </RedHatDisplay>
                        <RedHatDisplay
                            varfontSize  = '16px'
                            varweight = '400'
                            varcolor = '#77216B'
                            vartextAlign ='left'
                            varlineHeight = '1.5em'
                            varpadding = '14px 0 0'
                            className='icon-color'
                        >
                           Duerme tranquilo sabiendo que<strong> tu negocio está en las mejores manos.</strong>
                        </RedHatDisplay>
                    </ContentBox>
                </Grid>
                <Grid item xs={12} md={6} style={{ borderBottom: 'solid 0.5px #F1ABD4'}} className='column-identidad'>
                <ContentBox
                    varmaxwidth = '556px'
                    varwidth = '90%' 
                    varsmmaxwidth = '300px' 
                    >
                        <Gridwrapper
                            gridtemplatecolumns = '90% 9%'
                            varGridGap = '5px'
                            varjustifyContent = 'flex-start'
                            varmaxwidth = '258px'
                            varwidth = '100%'
                            varmargin = '0 0 92px'
                            marginMovil = '0 0 50px'
                        >
                            <RedHatDisplay
                            varfontSize  = '16px'
                            varweight = '800'
                            varcolor = '#2A2FAB'
                            vartextAlign = 'left'
                            varlineHeight = '1.5em'
                            movilweight = '500'
                            className='icon-color'
                            >
                                Educación y Empoderamiento
                            </RedHatDisplay>
                            <LocalLibrary className='icon-color' />
                        </Gridwrapper>
                        <RedHatDisplay
                            varfontSize  = '16px'
                            varweight = '400'
                            varcolor = '#263238'
                            vartextAlign ='left'
                            varlineHeight = '1.2em'
                            movilweight = '300'
                            className='icon-color' 
                        >
                            No solo creamos soluciones de eCommerce, <strong>te capacitamos</strong> para que las domines.<br/><br/>
                            Brindamos <strong>conocimiento experto,</strong> enseñándote herramientas y estrategias clave para que tomes decisiones informadas.<br/><br/>
                            Gestiona tu eCommerce con <strong>confianza y seguridad</strong>, proporcionándote autonomía total.                        </RedHatDisplay>
                    </ContentBox>
                </Grid>
                <Grid item xs={12} md={6} style={{ borderLeft: 'solid 0.5px #F1ABD4', borderBottom: 'solid 0.5px #F1ABD4'}} className='column-identidad'>
                <ContentBox
                        varmaxwidth = '556px'
                        varwidth = '90%'
                        varsmmaxwidth = '300px' 
                    >
                        <Gridwrapper
                            gridtemplatecolumns = '90% 9%'
                            varGridGap = '5px'
                            varjustifyContent = 'flex-start'
                            varmaxwidth = '261px'
                            varwidth = '100%'
                            varmargin = '0 0 92px'
                            marginMovil = '0 0 50px'
                        >
                            <RedHatDisplay
                            varfontSize  = '16px'
                            varweight = '800'
                            varcolor = '#2A2FAB'
                            vartextAlign = 'left'
                            varlineHeight = '1.5em'
                            className='icon-color'
                            >
                                Flexibilidad Post-Lanzamiento
                            </RedHatDisplay>
                            <Extension className='icon-color'/>
                        </Gridwrapper>
                        <RedHatDisplay
                            varfontSize  = '16px'
                            varweight = '400'
                            varcolor = '#263238'
                            vartextAlign ='left'
                            varlineHeight = '1.2em'
                            className='icon-color'
                        >
                            Opciones flexibles para que sigas creciendo:                        
                        </RedHatDisplay>
                        <ul>
                            <li className='li-color'>
                                Interpretamos tus datos para tomar decisiones con <strong>Asesoría Continua.</strong>
                            </li>
                            <li className='li-color'>
                                <strong>Nuestras herramientas</strong> a tu disposición para gestionar tus relaciones de forma eficiente.
                            </li>
                            <li className='li-color'>
                                Elige el <strong>nivel de soporte</strong> que mejor se adapte a tus necesidades.
                            </li>
                        </ul>
                    </ContentBox>
                </Grid>
            </Grid>
            
        </Box>
        <Btn
               backgcolor = '#4E1A9C'
               varpadding = '16px 28px'
               varmargin = '92px 0 0'
               varmaxwidth = '341px'
               varwidth = '100%' 
               varRadius = '0px'
               varfontsize = '18px'
               varweight = '500'
               smFontSize = '14px'
               className='btn'
               onClick={() => window.location.href = '/calendario'} 
            >
                Revisemos tu negocio
            </Btn>
    </div>
  )
}

export default IDentidad