import React from 'react'
import { ContentBox, Griditem, Gridwrapper, RedHatDisplay } from '../Styled'
import './Contacto.scss'

const Contacto = () => {
    let llegara_el_momento_indicado = require('../../assets/llegara_el_momento_indicado.png')
  return (
    <ContentBox
        varminheight ='51.7vh'
        vardisplay = 'flex'
        varminheightmydivice = '62vh'
        Movilpadding = '0 0 55px'
        varpadding = '0 0 46px'
    >
        <Gridwrapper
            varmaxwidth = '1250px'
            varwidth ='94%'
            gridtemplatecolumns = '50.6% 42%'
            justifyContent = 'space-between'
            columnsMovil = '100%'
        >
            <Griditem>
                <img src={llegara_el_momento_indicado} alt="llegara_el_momento_indicado" style={{maxWidth: '100%'}} />
            </Griditem>
            <Griditem>
                <RedHatDisplay
                    varcolor = '#263238'
                    varfontSize = '18px'
                    varweight = '700'
                    varlineHeight = '1.5em'
                    vartextAlign = 'left'
                >
                    ¿Quién no quiere crecer sabiendo algo nuevo cada semana?
                </RedHatDisplay>
                <ContentBox
                varminheight = '60px'
                >
                    <iframe
                    src="https://link.superleads.mx/widget/form/0C00Z4udmao2qjo39pPz"
                    style={{width:'100%',height:'100%', border:'none', borderRadius:'0px'}}
                    id="inline-0C00Z4udmao2qjo39pPz" 
                    data-layout="{'id':'INLINE'}"
                    data-trigger-type="alwaysShow"
                    data-trigger-value=""
                    data-activation-type="alwaysActivated"
                    data-activation-value=""
                    data-deactivation-type="neverDeactivate"
                    data-deactivation-value=""
                    data-form-name="Formulario de registro inicio"
                    data-height="400"
                    data-layout-iframe-id="inline-0C00Z4udmao2qjo39pPz"
                    data-form-id="0C00Z4udmao2qjo39pPz"
                    title="Formulario de registro inicio"
                    className='form-container-iframe'
                        >
                    </iframe>
                    <script src="https://link.superleads.mx/js/form_embed.js"></script>

                </ContentBox>
                <RedHatDisplay
                    varcolor = '#263238'
                    varfontSize = '18px'
                    varweight = '400'
                    varlineHeight = '1.5em'
                    vartextAlign = 'left'
                    varmargin = '-38px 0 0'
                    smFontSize = '16px'
                    smMargin = '-10px 0 0'
                >
                    Queremos estar <strong>presentes</strong> para ti en cuanto la oportunidad sea la correcta. Confiamos en el potencial de tu tienda y tu producto.                </RedHatDisplay>
            </Griditem>
        </Gridwrapper>

    </ContentBox>
  )
}

export default Contacto