import React from 'react';
import mejoraTuTienda from '../../assets/mejora_tu_tienda.png';
import kittle from '../../assets/IngenieriaDigital_kittle+kidge.png';
import DecoracionTienda from './DecoracionTienda';

const TiendaKittlekidge = () => {
  return (
    <div style={{ width: '100%', height: '100%', boxSizing: 'border-box' }}>
      <DecoracionTienda 
        imageMejoraTuTienda={mejoraTuTienda}
        imageConectaVelas={kittle}
        titleText="para los más pequeños"
        descriptionText="No hay tienda grande o pequeña. Así como con nuestros clientes, sabemos que puedes vender"
      />
    </div>
  );
};

export default TiendaKittlekidge;