import React from 'react'
import { ContentBox, ImagePicture } from '../components/Styled'

const Calendario = () => {
    let agenda_cita_text = require('../assets/agenda_cita2.png')
    let agenda_cita_text_movil = require('../assets/agenda_cita1.png')
  return (
    <ContentBox
    varminheight = '95vh'
    smMinHeight = '117vh'
    vardisplay = 'flex'
    
    style={{background: '#F6F6F6'}}
    >
        <ContentBox
        varmaxwidth = '1300px'
        varwidth = '88%'
        varminheight = '88vh'
        smMinHeight = '96vh'
        vardisplay = 'flex'
        >
            <ImagePicture 
                varwidth = '64%'
                varmargin = '64px 0 -16px'
                varmarginmovil = '40px 0 30px'
                vardisplay ='block'
                vardisplaymovil ='none'
                src={agenda_cita_text}
            />
            <ImagePicture 
                varwidth = '94%'
                varmargin = '64px 0 -16px'
                varmarginmovil = '40px 0 30px'
                vardisplay ='none'
                vardisplaymovil ='block'
                src={agenda_cita_text_movil}
            />
            <iframe src="https://link.superleads.mx/widget/booking/wtllAThGhGLk85OefP7y" 
                style={{width: '100%',border:'none', overflow: 'hidden', height: '100%', minHeight: 'inherit'}} 
                scrolling="no" id="wtllAThGhGLk85OefP7y_1724191054576"
                title='calendario_id'>
            </iframe>
            <script src="https://link.superleads.mx/js/form_embed.js" type="text/javascript"></script>
        </ContentBox>
        
    </ContentBox>
  )
}

export default Calendario