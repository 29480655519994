import React from 'react'
import './TextBanner.scss'
import { RedHatDisplay } from '../Styled'

const TextBanner = () => {

  let triangle = require('../../assets/tringle_figure.png')
  return (
    <div className='container'>
      <div class="banner">
        <div class="banner-content">
            <RedHatDisplay
                varfontSize = '20px'
                varcolor = '#fff'
                varweight = '500'
                smFontSize = '16px'
                style={{letterSpacing: '3px'}}
            >
                CONOCE NUESTRAS HERRAMIENTAS Y LLEVA TU ECOMMERCE AL SIGUIENTE NIVEL
            </RedHatDisplay>
        </div>
    </div>
    <img src={triangle} alt="triangle" className='triangle-figure'/>
    </div>
  )
}

export default TextBanner