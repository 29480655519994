import React from 'react'
import { Btn, ContentBox, Griditem, Gridwrapper, RedHatDisplay } from '../Styled'
import './Footer.scss'
import { Link } from 'react-router-dom'
// import { WhatsApp } from '@mui/icons-material'

const Footer = () => {
    let reestructuramosEcommerce = require('../../assets/restructuramos_ecommerce.png')
    let ingenieria_digital_logo = require('../../assets/ingnieria_digital_logo_white.png')
  return (
    <ContentBox
        varminheight = '62vh'
        vardisplay = 'flex'
        justifyContent = 'flex-end'
        backcolor ='#fafafa'
        className='footerbg'
        varpadding = '40px 0 0'
        smMinHeight = '54vh'
    >
        <ContentBox
            varminheight = '46vh'
            backcolor = '#2E1967'
            varwidth = '100%'
            vardisplay = 'flex'
            smMinHeight = '34vh'

        >
            <img src={reestructuramosEcommerce} alt="Ingenieria Digital Reestructuramos Ecommerce" className='logo-ecommerce'/>
            <Gridwrapper
                varmargin = '72px 0 48px'
                varmaxwidth = '1009px'
                varwidth = '90%'
                gridtemplatecolumns  = '20.7% 37% 26%'
                justifyContent = 'space-between'
                varalignItems = 'start'
                columnsMovil = '100%'
                marginsmMovil = '40px 0 48px'
            >
                <Griditem
                vardisplayMovil = 'flex'
                >
                    <Link to='/'>
                        <img src={ingenieria_digital_logo} alt="ingenieria_digital_logo" className='ingenieria_digital_logo'/> 
                    </Link>
                </Griditem>
                <Griditem
                vardisplayMovil = 'flex'
                >
                    <Link to = 'mailto:contacto@ingenieriadigital.mx'>
                        <RedHatDisplay
                            varfontSize ='16px'
                            varcolor = '#fff'
                            varpadding = '0 0 13px'
                        >
                            Contacto@IngenieriaDigital.mx
                        </RedHatDisplay>
                    </Link>
                    <Link to = 'https://goo.gl/maps/B2ePTaERBtfmPuRa9'>
                        <RedHatDisplay
                            varfontSize ='14px'
                            varcolor = '#fff'
                            varpadding = '0 0 13px'
                            varlineHeight ='1.4em'
                        >
                            Pedro Camino 242 - 1 Col. Aplicación Los Ángeles 
                            27140 Torreón Coahuila 
                        </RedHatDisplay>
                    </Link>
                  

                    {/* <Btn
                        backgcolor = '#F2F3FF'
                        varcolor = '#2A2FAB'
                        varfontfamily = '18px'
                        varweight = '750'
                        varpadding = '10px 28px'
                        vargridgap = '14%'
                        varRadius = '0px'
                        varmaxwidth = '341px'
                        varwidth = '100%' 
                        smFontSize = '12px'
                        smWidth = '73%'
                        smmaxwidth = '341px'                        
                        smPadd = '8px 28px'
                        as="a" // Esto convierte el botón en un enlace
                        href="https://wa.me/5215586193591?text=Hola%2C%20quiero%20más%20información%20acerca%20de%20Ingeniería%20Digital."
                    >
                        <WhatsApp style={{color: '#27B43E'}} /> 
                        WhatsApp
                    </Btn> */}
                </Griditem>
                <Griditem
                vardisplayMovil = 'flex'
                >
                    <Btn
                        backgcolor = '#F2F3FF'
                        varcolor = '#2A2FAB'
                        varfontfamily = '18px'
                        varweight = '75s0'
                        varpadding = '10px 28px'
                        varRadius = '0px'
                        varmaxwidth = '262px'
                        varwidth = '100%' 
                        smFontSize = '12px'
                        smWidth = '58%'
                        smmaxwidth = '341px'
                        smPadd = '14px 28px'
                        hoverBackgcolor ='#000'
                        className='btn'
                        onClick={() => window.location.href = '/calendario'} 
                    >
                        Agendar un análisis inicial
                    </Btn>
                </Griditem>

            </Gridwrapper>
            <RedHatDisplay
                            varfontSize='14px'
                            varcolor='#fff'
                            varlineHeight='1.2em'
                            varpadding = '0 0 40px'
                            varmovildisplay  = 'none'
                            vardisplay = 'block'
                            >
                                ©️2024Todos los derechos reservados. &nbsp;
                            <a href={require('../../assets/Aviso_de_Privacidad_IngenieriaDigital.pdf')} target="_blank" rel="noopener noreferrer">Aviso de Privacidad</a> &nbsp; - &nbsp; 
                            <a href={require('../../assets/Pólitica_de_envío_y_devolución_IngenieriaDigital.pdf')} target="_blank" rel="noopener noreferrer">Política de Devolución</a> &nbsp;- &nbsp;
                            <a href={require('../../assets/Términos_y_Condiciones_IngenieriaDigital.pdf')} target="_blank" rel="noopener noreferrer">Términos y Condiciones</a> 
            </RedHatDisplay>
            <RedHatDisplay
                            varfontSize='14px'
                            varcolor='#fff'
                            varlineHeight='1.4em'
                            varpadding = '0 0 40px'
                            vardisplay = 'none'
                            varmovildisplay = 'block'
                            >
                            <a href={require('../../assets/Aviso_de_Privacidad_IngenieriaDigital.pdf')} target="_blank" rel="noopener noreferrer">Aviso de Privacidad</a> &nbsp; - &nbsp; 
                            <a href={require('../../assets/Pólitica_de_envío_y_devolución_IngenieriaDigital.pdf')} target="_blank" rel="noopener noreferrer">Política de Devolución</a> &nbsp;- &nbsp; <br/>
                            <a href={require('../../assets/Términos_y_Condiciones_IngenieriaDigital.pdf')} target="_blank" rel="noopener noreferrer">Términos y Condiciones</a> 
            </RedHatDisplay>
        </ContentBox>

    </ContentBox>
  )
}

export default Footer