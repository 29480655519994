import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
// import Button from '@mui/material/Button';
// import MenuItem from '@mui/material/MenuItem';
import { LogoDesktop, LogoMovil, Tipograia } from '../Logo';
import { Btn } from '../Styled';
import './Header.scss';
import { Link } from 'react-router-dom';

// const pageToUrlMap = {
//   Iceberg: '/Iceberg',
//   Adquisición: '/adquisicion',
//   Blog: '/blog'
// };

function Header() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  let logo = require('../../assets/IngenieriaDigital_restructutamos_ecommerce_logo.png');
  let logoMovil = require('../../assets/IngenieriaDigital_icon.png');
  let typografyLogo = require('../../assets/IngenieriaDigital_restructutamos_ecommerce_logo.png');

//   const pages = Object.keys(pageToUrlMap);

  return (
    <AppBar position="static" style={{ backgroundColor: '#F6F8FF', padding: '13px 0'}}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
            <Link to="/">
                <LogoDesktop src={logo} alt="Logo" />
            </Link>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }} style={{ justifyContent: 'space-between', alignItems: 'center' }}>
            <Link to="/">
                <LogoMovil src={logoMovil} alt="Logo" />
            </Link>
            <Link to="/">
                <Tipograia src={typografyLogo} alt='tipografy superleads '/>
            </Link>

            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="#4D5FC7"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {/* {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <RedHatDisplay vartextalign="center" varcolor='#626262' hoverweight='700' colorHover = '#000'>
                    {page}
                  </RedHatDisplay>
                </MenuItem>
              ))}
               */}
              <Btn vargridgap='8px' varfontsize = '12px' mdPadd = '9px 11px' varmarginMovil = '0 6px' className='btn'>
                Pide tu análisis inicial
              </Btn>
            </Menu>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} style={{justifyContent: 'flex-end'}}>
            {/*<Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} style={{ justifyContent: 'flex-end', paddingRight: '40px' }}>
              {pages.map((page) => (
                <Button key={page} onClick={handleCloseNavMenu} sx={{ my: 2, color: 'white', display: 'block' }}>
                  <RedHatDisplay style={{ textTransform: 'none' }} vartextalign="center" varweight = '400' varcolor='#626262' hoverweight='800' varfontSize='16px'>
                    {page}
                  </RedHatDisplay>
                </Button>
              ))}
            </Box> */}
            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
              <Box sx={{ display: { xs: 'none', md: 'flex' } }} style={{ alignItems: 'center' }}>
                <Btn vargridgap='8px' displayMovil='none' backgcolor='#F2F3FF' varcolor= '#462A92' varRadius = '0px' className='btn'
                  onClick={() => window.location.href = '/calendario'} 
                >
                    Pide tu análisis inicial
                </Btn>
              </Box>
            </Box>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Header;