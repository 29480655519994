import styled from 'styled-components';

export const LogoDesktop = styled.img`
  display: none;
  margin-right: 1rem; // Puedes ajustar según tus necesidades

  @media (min-width: 900px) { // md breakpoint in Material-UI is 960px
    display: flex;
    width: 100%;
    max-width: 228px;
  }
`;
export const LogoMovil = styled.img`
  display: flex;
  width: 100%;
  max-width: 127px;

  @media (min-width: 900px) { // md breakpoint in Material-UI is 960px
    display: none;
  }
`;
export const Tipograia = styled.img`
  display: flex;
  width: 100%;
  max-width: 65%;
  margin: auto;
  flex-grow: 1;

  @media (min-width: 900px) { // md breakpoint in Material-UI is 960px
    display: none;
  }
`;
