import React from 'react'
import { Btn, ContentBox, Griditem, Gridwrapper, RedHatDisplay } from '../Styled'
import './Control.scss'

const Control = () => {
    let iconDiamond = require('../../assets/icon.png')
  return (
    <ContentBox
        vardisplay ='flex'
        varminheight = '38.7vh'
    >
        <Gridwrapper
            gridtemplatecolumns = '5% 89% 5%'
            varmaxwidth ='949px'
            varwidth = '90%'
            varalignItems = 'start'
            movilWidth = '98%'
            columnsMovil = '7% 75% 7%'
        >
            <Griditem>
                <img src={iconDiamond} alt="iconDiamond" className='iconDiamond' />
            </Griditem>
            <Griditem
                vardisplay = 'flex'
            >
                <RedHatDisplay
                    varfontSize = '30px'
                    varlineHeight = '1.6em'
                    varpadding = '0 0 52px'
                    varweight = '400'
                    smFontSize   = '18px'
                >
                    Saca el máximo provecho. <br/>
                    Crece tu negocio y <strong>toma el control</strong> de tu presencia digital.
                </RedHatDisplay>
                <Btn
                    backgcolor = '#F2F3FF'
                    varcolor = '#2A2FAB'
                    varfontfamily = '18px'
                    varweight = '800'
                    varpadding = '16px 28px'
                    varRadius = '0px'
                    varmaxwidth = '341px'
                    varwidth = '80%' 
                    smFontSize = '12px'
                    smWidth = '67%'
                    smPadd = '12px 28px'
                    onClick={() => window.location.href = '/calendario'} 
                    className='btn'
                >
                    Revisemos tu negocio
                </Btn>
            </Griditem>
            <Griditem>
                <img src={iconDiamond} alt="iconDiamond" className='iconDiamond' />
            </Griditem>
        </Gridwrapper>
    </ContentBox>
  )
}

export default Control