import React from 'react'
import { Griditem, Gridwrapper, InterGradient, RedHatDisplay } from '../Styled'
import './FelicidadesComponent.scss'

const FelicidadesComponent = () => {

    let logo = require('../../assets/IngenieriaDigital_logo.png')

  return (
    <div className='FelicidadesContainer'>
        <Gridwrapper
            varmaxwidth = '1212px'
            varwidth = '95%'
            gridtemplatecolumns = '100%'
            columnsMovil600 = '100%'
        >
            <Griditem
                display = 'flex'
                paddMovil = '0 0 0 0'
            >
                <img src={logo} alt='logo ingenieria digital' style={{ width:'80%', margin:'auto'}} className='logo'  />
                <InterGradient
                    varfontFamily = '"Red Hat Display", sans-serif'
                    varweight = '700'
                    varfontSize = '6em'
                    vardisplay = 'inherit'
                    varlineHeight = '1.40em'
                    fontSizeMobile600 = '62px'
                    fontSizeMobile = '4em'
                >
                    Felicidades
                </InterGradient>
                <InterGradient
                    varfontFamily = '"Red Hat Display", sans-serif'
                    varweight = '400'
                    varfontSize = '5em'
                    varpadding = '0 0 56px'
                    vardisplay = 'inherit'
                    fontSizeMobile600 = '50px'
                    MovilPadd = '0 0 26px'
                    fontSizeMobile = '3em'
                >
                    ¡Suscrito!  
                </InterGradient>
                <RedHatDisplay
                    varfontSize = '1.8em'
                    varcolor='#545454'
                    varweight = '400'
                    varmaxWidth = '458px'
                    vartextAlign = 'center'
                    vardisplay = 'inherit'
                    varlineHeight = '1.30em'
                    fontSizeMobile600 = '18px'
                    maxWidthMovil = '345px'
                    varpadding = '0 0 26px'
                    fontSizeMobile = '1.4em'
                >
                    Nos alegra mucho que estés aquí. 🎉Tu guía está en camino.
                </RedHatDisplay>
                <RedHatDisplay
                    varfontSize = '1.8em'
                    varcolor='#545454'
                    varweight = '400'
                    varmaxWidth = '459px'
                    vartextAlign = 'center'
                    vardisplay = 'inherit'
                    varlineHeight = '1.30em'
                    fontSizeMobile600 = '18px'
                    maxWidthMovil = '345px'
                    fontSizeMobile = '1.4em'
                >
                    Esperamos que encuentres en ella ideas prácticas y útiles para hacer crecer tu tienda online.
                </RedHatDisplay>
            </Griditem>
            
        </Gridwrapper>
    </div>
  );
};

export default FelicidadesComponent;