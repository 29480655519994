import React, { useState, useEffect, useRef } from 'react';
import './ScrollSection.scss';

const ScrollSection = ({ components = [] }) => {
  const [currentComponent, setCurrentComponent] = useState(0);
  const scrollContainerRef = useRef(null);
  const numComponents = components.length;

  useEffect(() => {
    let timeout;
    const container = scrollContainerRef.current;

    const handleScroll = (e) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        const direction = e.deltaY > 0 ? 1 : -1;

        if (direction === 1 && currentComponent < numComponents - 1) {
          setCurrentComponent(prevComponent => Math.min(prevComponent + 1, numComponents - 1));
        } else if (direction === -1 && currentComponent > 0) {
          setCurrentComponent(prevComponent => Math.max(prevComponent - 1, 0));
        }
      }, 150); // Ajusta el tiempo de debounce según sea necesario
    };

    container.addEventListener('wheel', handleScroll);
    return () => {
      clearTimeout(timeout);
      container.removeEventListener('wheel', handleScroll);
    };
  }, [currentComponent, numComponents]);

  return (
    <div className='ScrollSection' ref={scrollContainerRef}>
      <div className='ScrollSection-Wrapper' style={{ transform: `translateY(-${currentComponent * 100}vh)` }}>
        {components.length > 0 ? (
          components.map((component, index) => (
            <div className='ScrollSection-Item' key={index}>
              {component}
            </div>
          ))
        ) : (
          <p>No components to display</p>
        )}
      </div>
    </div>
  );
};

export default ScrollSection;